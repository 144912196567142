<template>
  <div class="container">
    <div
      class="item"
      v-for="(item, index) in pageData"
      :key="index + 'pageData'"
    >
      <div class="detailBox">
        <div class="detailBox-video" @click="playVideo(item.videoPath)">
          <video class="video-preview" :src="item.videoPath"></video>
        </div>
        <div class="detailBox-infoTitle">
          <div>
            <div class="infoTitle-model">{{ item.DRONEMODEL }}</div>
            <div class="infoTitle-createDate">
              {{ dateFormat(item.createDate) }}
            </div>
          </div>
          <div>
            <div class="infoTitle-duration">
              {{ item.duration === null ? '暂无时长' : 'item.duration' }}
            </div>
            <div class="infoTitle-isUpload">
              {{ item.isUpload ? '已上传' : '未上传' }}
            </div>
          </div>
        </div>
        <div class="detailBox-infoContent">
          <div>
            <div class="infoContent-title">用户</div>
            <div class="infoContent-user">{{ item.userName }}</div>
          </div>
          <div>
            <div class="infoContent-title">团队</div>
            <div class="infoContent-team">{{ item.teamName }}</div>
          </div>
        </div>
        <div class="infoContent-upload">
          <Button type="info" class="upload-btn">上传</Button>
        </div>
      </div>
    </div>
    <div class="no-data" v-if="!pageData.length">
      <img class="no-data-img" :src="noData" alt="" />
      暂无数据
    </div>
    <!-- 视频窗口 -->
    <article class="videoModal" v-show="videoModal">
      <Icon
        type="md-close"
        color="white"
        class="videoModal-close"
        size="20"
        @click="closeVideoModal()"
      />
      <video
        class="videoModal-video"
        controls
        ref="video"
        :src="bigVideo"
        autoplay
      ></video>
    </article>

    <div class="paging">
      <Page
        :total="total"
        :page-size="pageSize"
        :current="parseInt(page)"
        size="small"
        show-total
        :page-size-opts="[10, 15, 20]"
        @on-page-size-change="pageSizeChange"
        @on-change="changePage"
      />
    </div>
  </div>
</template>

<script>
import Api from '@/utils/api.js'
import noData from '@/assets/img/noData.png'
export default {
  data() {
    return {
      user_info: {}, //用户信息
      pageData: [], //列表数据
      noData, //无数据图片
      bigVideo: '', //弹窗内的video地址
      videoModal: false, //控制video弹窗变量
      total: 0, //数据数量
      pageSize: 10,
      page: 1,
    }
  },

  mounted() {
    this.user_info = JSON.parse(sessionStorage.getItem('user_info'))
    this.getTrackVideo()
  },

  destroyed() {},

  methods: {
    //获取轨迹视频数据
    getTrackVideo() {
      this.$post(Api.getTrackVideo(), {
        page: this.page,
        size: this.pageSize,
        team_id: this.user_info.team_id,
      }).then((res) => {
        this.pageData = res.data.data
        this.total = res.data.total
      })
    },
    //打开弹窗
    playVideo(path) {
      document.documentElement.style.overflowY = 'hidden'
      this.bigVideo = path
      this.videoModal = true
    },
    //关闭弹窗
    closeVideoModal() {
      document.documentElement.style.overflowY = 'auto'
      this.$refs.video.pause()
      this.bigVideo = ''
      this.videoModal = false
    },
    //翻页
    pageSizeChange(size) {
      this.page = 1
      this.pageSize = size
      this.getTrackVideo()
    },
    changePage(page) {
      this.page = page
      this.getTrackVideo()
    },
    //格式化时间 年-月-日 时:分:秒
    dateFormat(time) {
      const t = new Date(time)
      // 日期格式
      const format = 'Y-m-d h:i:s'
      const year = t.getFullYear()
      // 由于 getMonth 返回值会比正常月份小 1
      const month = t.getMonth() + 1
      const day = t.getDate()
      const hours = t.getHours()
      const minutes = t.getMinutes()
      const seconds = t.getSeconds()
      const hash = {
        Y: year,
        m: month,
        d: day,
        h: hours,
        i: minutes,
        s: seconds,
      }
      return format.replace(/\w/g, (o) => {
        return hash[o]
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.container {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  padding: 50px 170px 0px;
  overflow: auto;
  .detailBox {
    margin: 0 15px 30px;
    background: #383838;
    color: #c9caca;
    padding-bottom: 20px;
    .detailBox-video {
      width: 240px;
      height: 120px;
      .video-preview {
        margin: 0 auto;
        height: 2.34rem;
      }
    }
    .detailBox-infoTitle {
      display: flex;
      justify-content: space-between;
      padding: 10px;
      border-bottom: 1px solid #e6e6e6;
      div {
        .infoTitle-model {
          font-size: 14px;
          font-weight: 600;
          line-height: 1;
          margin-bottom: 10px;
        }
        .infoTitle-createDate {
          font-size: 12px;
          font-weight: 400;
          line-height: 1;
        }
      }
      div {
        .infoTitle-duration {
          font-size: 14px;
          font-weight: 600;
          line-height: 1;
          margin-bottom: 10px;
        }
        .infoTitle-isUpload {
          text-align: right;
          font-size: 12px;
          font-weight: 400;
          line-height: 1;
        }
      }
    }
    .detailBox-infoContent {
      display: flex;
      justify-content: space-between;
      padding: 10px;
      div {
        .infoContent-title {
          font-size: 12px;
          font-weight: 400;
          margin-bottom: 4px;
          color: #999;
        }
        .infoContent-user {
          font-size: 12px;
          font-weight: 400;
          line-height: 17px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
    .infoContent-upload {
      display: flex;
      justify-content: flex-end;
      padding: 5px 10px;
      .upload-btn {
        font-size: 12px;
        padding: 0px 10px;
      }
      /deep/ .ivu-btn {
        height: 18px !important;
      }
    }
  }
  // 弹窗

  .videoModal {
    position: fixed;
    z-index: 1200;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(23, 23, 23, 0.84);
    display: flex;
    justify-content: center;
    align-items: center;
    .videoModal-close {
      position: absolute;
      top: 15px;
      right: 15px;
      padding: 5px;
      cursor: pointer;
      z-index: 99;
      background-color: #000;
      border-radius: 50%;
    }
    .videoModal-video {
      height: 50%;
      width: 50%;
    }
  }
  .no-data {
    width: 100%;
    height: 100%;
    font-size: 16px;
    text-align: center;
    padding-top: 5rem;
    .no-data-img {
      margin: 0 auto;
    }
  }
  .paging {
    position: fixed;
    bottom: 10px;
    right: 170px;
  }
}
</style>